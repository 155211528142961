/* eslint-disable max-lines */
import React from 'react'
import { Flex, Text } from '@radix-ui/themes'
import { LampOnIcon } from 'shared/icons'
import {
  modeOnboardingBullsEyeBanner1,
  modeOnboardingBullsEyeBanner2,
  modeOnboardingBullsEyeBanner3
} from 'shared/images'
import { OnboardingContainer } from './onboarding-container'
import styles from '../onboarding.module.scss'

export const OnboardingBullsEye: React.FC = () => {
  return (
    <OnboardingContainer>
      <Text
        weight={'bold'}
        className={styles.onboardingPageTitle}
        mb={'4'}
      >
        {"Bull's Eye"}
      </Text>

      <Flex
        direction={'column'}
        className={styles.upDownContentContainer}
      >
        <Flex direction={'column'}>
          <Flex
            width={'100%'}
            mb={'4'}
          >
            <img
              src={modeOnboardingBullsEyeBanner1}
              alt='modeOnboardingBullsEyeBanner1'
              className={styles.onboardingBanner}
            />
          </Flex>

          <Text
            className='color-gray-light'
            weight={'medium'}
            size={'3'}
          >
            Bull’s Eye is for those who can analyze the market because the goal
            is to guess the future price as accurately as possible, not just the
            direction (as in Up/Down). It doesn’t matter if the estimate is
            higher or lower than the actual price; what matters is the
            difference between the two.
          </Text>
        </Flex>

        <Flex direction={'column'}>
          <Flex
            width={'100%'}
            mb={'4'}
          >
            <img
              src={modeOnboardingBullsEyeBanner2}
              alt='modeOnboardingBullsEyeBanner2'
              className={styles.onboardingBanner}
            />
          </Flex>

          <Text
            className='color-gray-light'
            weight={'medium'}
            size={'3'}
          >
            Currently, the game is only available for BTC, with plans to add
            other cryptocurrencies. There is one timeframe: 3 minutes, and each
            user has 1 minute (1/3 of the timeframe) to try and guess the exact
            price of the asset at the end of the period.
          </Text>
        </Flex>

        <Flex direction={'column'}>
          <Flex
            width={'100%'}
            mb={'4'}
          >
            <img
              src={modeOnboardingBullsEyeBanner3}
              alt='modeOnboardingBullsEyeBanner3'
              className={styles.onboardingBanner}
            />
          </Flex>

          <Text
            size={'4'}
            weight={'bold'}
            className='color-white'
          >
            The game works as follows:
          </Text>

          <ul className={styles.listContainer}>
            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                {' '}
                The player chooses an amount to play with and enters a price
                estimate.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                All player forecasts are compared to the actual price, and the
                one closest to it is selected.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                The player whose estimate is closest to the actual price takes
                home 50% of the money in the pool if their forecast hits the
                bull’s eye (closest guess). Those who come second and third in
                terms of precision get smaller prizes.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                The player chooses an amount to play with and enters a price
                estimate.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                The pool is then reset and starts accumulating funds again.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                The platform fee is 15% of the pool amount.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                If no one reaches the exact price (0.001%), the prize
                distribution is as follows: 1st place - 50%, 2nd place - 35%,
                3rd place - 15%.
              </Text>
            </li>

            <li className={styles.listContainerItem}>
              <Text
                className='color-gray-light'
                weight={'medium'}
                size={'3'}
              >
                If one of the players reaches the exact price, the distribution
                is as follows: 1st place - 75%, 2nd place - 15%, 3rd place -
                10%.
              </Text>
            </li>
          </ul>
        </Flex>

        <Flex
          className={styles.exampleBox}
          position={'relative'}
          direction={'column'}
        >
          <Flex
            align={'center'}
            gap={'4'}
            mb={'3'}
          >
            <LampOnIcon />

            <Text
              size={'6'}
              weight={'medium'}
              className='color-white'
            >
              Example
            </Text>
          </Flex>

          <Text
            className='color-white'
            size={'3'}
            weight={'medium'}
            mb={'5'}
          >
            Alice estimates that the price of BTC will reach $66,503, while
            Bob’s forecast is $66,530, and Frank’s is $66,550. At the end of the
            round, the actual price is $66,512. Alice’s guess is $9 below the
            actual price, which is within 0.0001% of the actual price, while
            Bob’s is $18 higher, and Frank’s is $38 higher. Assuming other
            players in the pool are further from the real price, Alice wins over
            both Bob and Frank and gets 75% of the money in the pool (minus the
            15% platform fee).
          </Text>
        </Flex>
      </Flex>
    </OnboardingContainer>
  )
}
