import { useCallback, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import * as RadixForm from '@radix-ui/react-form'
import { Box, Button, Flex, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { balanceVar } from 'shared/store/balance-store'
import { AmountField, BetsTypeSwitcher, XyroLoading } from 'shared/ui'
import { BALANCE_CLAIM_SWITCH_TYPES } from '../constants'
import { useApproveBalanceFormSubmit } from '../hooks/use-approved-balance-form-submit'
import { activeBalanceSwitchTypeVar } from '../store/switch-types'
import styles from '../approved-balance.module.scss'

/* eslint-disable-next-line max-statements */
export const ApprovedBalanceForm = () => {
  const [isDisabledButton, setButtonDisabled] = useState(true)

  const activeType = useReactiveVar(activeBalanceSwitchTypeVar)
  const balance = useReactiveVar(balanceVar)

  const isAppove = activeType === BALANCE_CLAIM_SWITCH_TYPES.Approve

  const { handleSubmit, loading } = useApproveBalanceFormSubmit()

  const handleChangeAmountValue = useCallback(
    (value: string) => setButtonDisabled(!value),
    [setButtonDisabled]
  )

  const formattedBalance = Number(balance.usdtBalance.toFixed(2))
  const formattedTreasury = Number(balance.treasuryDeposit.toFixed(2))

  const maxAmount = isAppove ? formattedBalance : formattedTreasury

  const fieldTitle = isAppove ? 'WALLET BALANCE' : 'AVAILABLE TO CLAIM'

  const balanceValue = `${isAppove ? formattedBalance : formattedTreasury} USDT`

  const buttonTitle = isAppove ? 'APPROVE' : 'CLAIM'

  return (
    <Box mt={'6'}>
      <Flex mb={'6'}>
        <BetsTypeSwitcher
          activeType={activeType}
          setActiveType={activeBalanceSwitchTypeVar}
          betsTypes={BALANCE_CLAIM_SWITCH_TYPES}
          size='4'
        />
      </Flex>

      <RadixForm.Root onSubmit={handleSubmit}>
        <Flex
          direction={'column'}
          align={'center'}
        >
          <Flex
            className={styles.approvedFormAmountLabel}
            gap={'1'}
          >
            <Text
              size={'1'}
              weight={'bold'}
              color='gray'
            >
              {fieldTitle}:
            </Text>

            <Text
              size={'1'}
              weight={'bold'}
              className={'color-white'}
            >
              {balanceValue}
            </Text>
          </Flex>

          <AmountField
            onInputChange={handleChangeAmountValue}
            name={'amount'}
            className={styles.approvedFormAmount}
            maxAmount={maxAmount}
            onlyInteger={!isAppove}
            maxButtonEnabled
          />

          <Button
            className={cn(styles.approvedFormButton, {
              [styles.approvedFormButtonDisabled]: isDisabledButton || loading
            })}
            disabled={isDisabledButton || loading}
            variant={loading ? 'outline' : 'classic'}
            type={'submit'}
            size={'4'}
            mt={'6'}
          >
            <XyroLoading loading={loading}>
              <Text
                size={'2'}
                weight={'bold'}
              >
                {buttonTitle}
              </Text>
            </XyroLoading>
          </Button>
        </Flex>
      </RadixForm.Root>
    </Box>
  )
}
