import React from 'react'
import { Flex, Text } from '@radix-ui/themes'
import { ExitCrossIcon, UserIcon } from 'shared/icons'
import { isChatOpenedVar } from 'shared/store/chat-state-store'
import { useOnlineUsers } from '../hooks/use-control-active-users'

export const ChatMobileHeader: React.FC = () => {
  const { activeUsersAmount } = useOnlineUsers()

  const handleCheckClick = () => {
    isChatOpenedVar(false)
  }

  return (
    <Flex
      width={'100%'}
      justify={'between'}
      px={'6'}
      py={'4'}
    >
      <Text
        size={'5'}
        className='color-white'
      >
        Global Chat
      </Text>

      <Flex
        align={'center'}
        gap={'1'}
      >
        <Flex mb={'1'}>
          <UserIcon className='color-green' />
        </Flex>
        <Text
          className='color-green'
          size={'4'}
        >
          {activeUsersAmount}
        </Text>
        <Flex
          width={'7'}
          height={'7'}
          onClick={handleCheckClick}
          justify={'center'}
          align={'center'}
        >
          <ExitCrossIcon
            color='#fff'
            onClick={handleCheckClick}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
