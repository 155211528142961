import { WSS_PROVIDER_URL, VITE_HTTP_PROVIDER, STAND } from 'app/constants'
import { createClient, defineChain } from 'viem'
import { http, createConfig, webSocket } from 'wagmi'
import { arbitrumSepolia } from 'wagmi/chains'
import { Stand } from './types'

const arbitrumOne = defineChain({
  id: 42_161,
  name: 'Arbitrum One',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://arbitrum.drpc.org']
    }
  },
  blockExplorers: {
    default: {
      name: 'Arbiscan',
      url: 'https://arbiscan.io',
      apiUrl: 'https://api.arbiscan.io/api'
    }
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 7654707
    }
  }
})

export const APP_CHAIN = STAND === Stand.mainnet ? arbitrumOne : arbitrumSepolia

const httpProvider = VITE_HTTP_PROVIDER || undefined

export const wagmiConfig = createConfig({
  chains: [APP_CHAIN],
  transports: {
    [arbitrumSepolia.id]: http(httpProvider),
    [arbitrumOne.id]: http(httpProvider)
  }
})

export const wagmiWsConfig = createConfig({
  chains: [APP_CHAIN],
  client: ({ chain }) =>
    createClient({ chain, transport: webSocket(WSS_PROVIDER_URL) })
})
